<template>
    <div class="mainDiv">
        <div class="topImg">
            <img :src="anlianIco" alt="" />
        </div>
        <div class="mainContent">
            <div class="subContent"  @click="toRouter('infoDisc')">信息披露 <span>>></span></div>
            <div class="subContent"  @click="toRouter('edutPro')">教育宣传 <span>>></span></div>
            <div class="subContent"  @click="open1()">权益查询 <span>>></span></div>

        </div>
        <div class="mainContent1">
            <div class="subContent1"  @click="open2()">业务咨询 <span>>></span></div>
            <div class="subContent1"  @click="toRouter('conCom')">消费投诉 <span>>></span></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "personalPension",
    data() {
        return {
            anlianIco:require('@/static/images/secondaryMenu/productsServices/bg_VIP_01@2x.png')
        };
    },
    created() {
        ///创建之前钩子
    },
    mountd(){
        //挂载钩子

    },
    methods: {
        //方法块儿
        toRouter(val){
            console.log('点击的是哪个按钮,不同按钮跳转不同的页面',val)
            if(val== 'conCom' ){
                //消费投诉
                this.$router.push("/personalCustomer/insuranceAfterSales/AZ020");
            }else if(val== 'infoDisc'){
                //信息披露
                this.$router.push("/public/publicDisclosureGy/AZ0070208");
            }else if(val== 'edutPro'){
                //教育宣传
                this.$router.push("/public/publicDisclosure/AZ01012");
            }
        },
        open1() {
            console.log('11111')
            this.$MessageBox.alert('<div style=""><p>尊敬的客户：您可致电安联人寿全国统一客服热线95342，或登录微信搜索“安联在线”，在“自助服务-自助服务-保单查询”中查询您的保单权益。<img style="width:150px;height:150px" src="https://www.allianz.com.cn/allianz/service.jpg" alt="" />扫描“安联在线”二维码，尽享更多保单自助服务</p></div>', '温馨提示', {
                dangerouslyUseHTMLString: true
            });
        },
        open2() {
            console.log('2222')
            this.$MessageBox.alert('<div><p>敬的客户：您可致电安联人寿全国统一客服热线95342进行咨询，或登录微信搜索“安联在线”，进入“个人养老金专区”，获取更多个人养老金信息。<img style="width:150px;height:150px" src="https://www.allianz.com.cn/allianz/service.jpg" alt="" />扫描“安联在线”二维码，尽享更多服务信息</p></div>', '温馨提示', {
                dangerouslyUseHTMLString: true
            });
        }
    },
   
}
</script>

<style lang="less" scoped>
.mainDiv{
    .topImg{
        img{
            width: 100%;
        }
    }
    .mainContent{
        display: flex;
        .subContent{
            border: 5px solid #b403ef;
            border-image:linear-gradient(130deg,#70b81c, #01b1e9) 20 20;
            width: 30%;
            margin: 3% 5%;
            padding: 3%;
            span{
                color: #01b1e9;
            }
        }
    }
    .mainContent1{
        margin: 0 10%;
        display: flex;
        margin-left: 25%;
        .subContent1{
            border: 5px solid #b403ef;
            border-image:linear-gradient(130deg,#70b81c, #01b1e9) 20 20;
            width: 30%;
            margin: 3%;
            padding: 4.6% 3%;
            span{
                color: #01b1e9;
            }
        }
    }
}
</style>