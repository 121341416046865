<template>
  <!-- 导航栏 -->
  <div class="box">
    <nav>
      <div class="nav">
        <ul class="nav_item">
          <!-- 关于安联 -->
          <li>
            <span>关于我们</span>
            <div class="menu2 menu2_box">
              <ul class="menu2_about about">
                <li>
                  <ul>
                    <li
                      v-for="item in aboutFirst"
                      :key="item.title"
                      class="aboutFirst"
                    >
                      <div
                        class="menu2_product_title"
                        @click.stop="details2(item.link)"
                      >
                        <img :src="item.img" alt="" />
                        <a>{{ item.title }}</a>
                      </div>
                    </li>
                  </ul>
                </li>
                <li v-for="item in about" :key="item.title">
                  <div
                    v-if="item.children"
                    class="menu2_product_title"
                  >
                    <img :src="item.img" alt="" />
                    <a>{{ item.title }}</a>
                  </div>
                  <ul v-if="item.children" class="Level3">
                    <li
                      v-for="items in item.children"
                      :key="items.text"
                    >
                      <div class="lineHeigth">
                        <div :class="{lineHeigth2:items.twoMenu}">
                          <span v-show="items.isCkick">{{ items.text }}</span> 
                          <span  v-show="!items.isCkick" @click.stop="details2(items.link)">{{ items.text }}</span> 
                        </div>
                        <div class="treeMunu" v-show="items.twoMenu">
                            <p v-for="it in items.twoMenu"
                              :key="it.title"
                              class="menu_two">
                            <span @click.stop="details2(it.link)"> {{it.text}}</span>
                            </p>
                        </div>
                      </div>
                        <!-- <div
                          v-for="it in items.twoMenu"
                          :key="it.text"
                          class="menu_two"
                        >
                          <span @click.stop="jumpTo(it.link)">{{
                            it.text
                          }}</span>
                        </div> -->

                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 产品中心 -->
          <li>
            <span>产品中心</span>
            <div class="menu2">
              <ul class="menu2_product">
                <li v-for="item in product" :key="item.title">
                  <div class="menu2_product_title">
                    <img :src="item.img" alt="" />
                    <a>{{ item.title }}</a>
                  </div>
                  <ul>
                    <li
                      v-for="items in item.children"
                      :key="items.title || items.categoryName"
                      @click="jumpTo1(items)"
                    >
                      <p>{{ items.title || items.categoryName }}</p>
                      <p>{{ items.text || "" }}</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 产品服务 -->
          <li>
            <span>产品服务</span>
            <div class="menu2">
              <ul class="menu2_service">
                <li v-for="item in services" :key="item.title">
                  <div class="menu2_product_title">
                    <img :src="item.img" alt="" />
                    <a>{{ item.title }}</a>
                  </div>
                  <ul>
                    <li v-for="items in item.children" :key="items.text">
                      <template v-if="htt(items.link)">
                        <a :href="items.link" target="_blank">{{
                          items.text
                        }}</a>
                      </template>
                      <p v-else @click.stop="jumpTo(items.link)">
                        {{ items.text }}
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <!-- 在线投保 -->
          <li>
            <span>在线投保</span>
            <div class="menu2">
              <ul class="menu2_news insured">
                <li v-for="item in onlineInsurance" :key="item.title">
                  <template v-if="htt(item.link)">
                    <img :src="item.img" alt="" class="search" />
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </template>
                  <template v-else>
                    <div @click.stop="details1(item.link)">
                      <img :src="item.img" alt="" class="search" />
                      <a>{{ item.title }}</a>
                    </div>
                  </template>
                </li>
              </ul>
            </div>
          </li>
          <!-- 新闻公告 -->
          <li>
            <span>新闻公告</span>
            <div class="menu2">
              <ul class="menu2_news menu_two_news">
                <li
                  v-for="item in news"
                  :key="item.text"
                  @click="jumpTo(item.link)"
                >
                  <img :src="item.img" alt="" class="search" />
                  <a>{{ item.text }}</a>
                </li>
              </ul>
            </div>
          </li>
          <!-- 职业生涯 -->
          <li>
            <span>职业生涯</span>
            <div class="menu2">
              <ul class="menu2_news">
                <!-- <li v-for="item in career" :key="item.title">
                  <template v-if="item.title === '全球机会'">
                    <img :src="item.img" alt="" class="search" />
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </template>
                </li> -->
                <li v-for="item in career" :key="item.title">
                  <template v-if="htt(item.link)">
                    <img :src="item.img" alt="" class="search" />
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </template>
                  <template v-else>
                    <div @click="jumpToRecruitment(item.link)">
                      <img :src="item.img" alt="" class="search" />
                      <a>{{ item.title }}</a>
                    </div>
                  </template>
                  <!-- <img :src="item.img" alt="" class="search" />
                  <a
                    v-if="item.title === '全球机会'"
                    :href="item.link"
                    target="_blank"
                    >{{ item.title }}</a
                  >
                  <a v-else>{{ item.title }}</a> -->
                </li>
              </ul>
            </div>
          </li>
          <!-- 服务通道 -->
          <li>
            <span>销售通道</span>
            <div class="menu2">
              <ul class="menu2_news sell">
                <li v-for="item in serviceChannel" :key="item.title">
                  <template v-if="htt(item.link)">
                    <img :src="item.img" alt="" class="search" />
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </template>
                  <template v-else>
                    <img
                      @click="onlineJumpTo(item.link)"
                      :src="item.img"
                      alt=""
                      class="search"
                    />
                    <a @click="onlineJumpTo(item.link)">{{ item.title }}</a>
                  </template>
                  <!--                  <img :src="item.img" alt="" class="search" />-->
                  <!--                  <a :href="item.link" target="_blank">{{ item.title }}</a>-->
                </li>
              </ul>
            </div>
          </li>
          <!-- 员工通道 -->
          <!-- <li><span>员工通道</span></li> -->
        </ul>
        <!-- <img src="@/static/images/ic_search@2x.png" alt="" class="search" /> -->
      </div>
      <ul class="information">
        <li>
          <span>公开信息披露</span>
          <div class="menu2 disclosure">
            <ul class="information_menu2">
              <li v-for="item in disclosure" :key="item.title" :class="{addWidth:item.title=='重大事项'}">
                <div>
                  <img :src="item.img" alt="" :class="{addWidthImg:item.title=='重大事项' || item.title=='年度信息'}"/>
                  <a>{{ item.title }}</a>
                </div>
                <div
                  v-for="items in item.twoMenu"
                  :key="items.title"
                  class="menu_two"
                >
                  <span @click.stop="jumpTo(items.link)">{{
                    items.title
                  }}</span>
                  <ul>
                    <li
                      v-for="ite in items.children"
                      :key="ite.text"
                      @click.stop="details(ite.link)"
                    >
                      {{ ite.text }}
                    </li>
                  </ul>
                </div>
                <div class="menu2_link">
                  <div
                    v-for="items in item.menu2"
                    :key="items.text"
                    class="menu_two"
                  >
                    <span @click.stop="jumpTo(items.link)">{{
                      items.text
                    }}</span>
                  </div>
                </div>
              </li>
              <!-- <li>
                <div
                  @click.stop="jumpTo(`/public/publicDisclosure/${'AZ008'}`)"
                >
                  <img
                    src="@/static/images/secondaryMenu/disclosure/Info Sheet@2x.png"
                    alt=""
                  />
                  <a>年度信息</a>
                </div>
                <div
                  @click.stop="jumpTo(`/public/publicDisclosure/${'AZ009'}`)"
                >
                  <img
                    src="@/static/images/secondaryMenu/disclosure/Certificate Papers@2x.png"
                    alt=""
                  />
                  <a>重大事项</a>
                </div>
              </li> -->
            </ul>
          </div>
        </li>
        ｜
        <li @click="jumpToHints(`/public/publicDisclosure/${'AZ021'}`)">
          <span>投保提示书</span>
        </li>
        ｜
        <li @click="jumpToHints(`/azContent/introduce/${'AZ022'}`)">
          <span>隐私政策</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  services,
  aboutFirst,
  about,
  news,
  product,
  onlineInsurance,
  serviceChannel,
  career,
  disclosure,
} from "@/constant";
import { getProductCategory } from "@/api/personal";

export default {
  name: "tab",
  data() {
    return {
      current: "",
      aboutFirst,
      about,
      news,
      product,
      services,
      onlineInsurance,
      serviceChannel,
      career,
      disclosure,
      list: [],
      zid: 0,
    };
  },
  created() {
    // console.log(this.$route);
    this.bannerList();
  },
  methods: {
    htt(str) {
      var reg = /^(http|https):\/\/.*/g;
      let flag = reg.test(str);
      // console.log(flag)
      return flag;
    },
    async bannerList() {
      // this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getProductCategory({
          timeStamp,
          sign,
          categoryType: 1,
        });
        // console.log("1111", res.data);
        if (res.data.code == 200) {
          this.product[1].children = res.data.data;
          // console.log("this.product",this.product)
          // this.zid = this.list[this.id].zid;
          // console.log("list", this.list[this.id].zid);
        }
      } catch (error) {
        console.log(error);
      }
      // this.loading = false;
    },
    jumpToHints(menu) {
      sessionStorage.removeItem("MENU");
      this.$router.push(menu);
    },
    onlineJumpTo(menu) {
      this.$router.push(menu);
    },
    jumpTo(menu) {
      // console.log("menu11",menu)
      if (menu == "/azContent/introduce/AZ026") {
        sessionStorage.setItem("MENU", "产品服务");
        this.$router.push(menu);
      } else if (
        menu == "/azContent/introduce/AZ01006" ||
        menu == "/azContent/introduce/AZ01009"
      ) {
        sessionStorage.setItem("MENU", "公开信息披露");
        this.$router.push(menu);
      } else if (menu == "/azContent/introduce/AZ01506") {
        sessionStorage.setItem("MENU", "新闻公告");
        this.$router.push(menu);
      } else if (menu == "/azContent/introduce/AZ022") {
        this.$router.push(menu);
      } else {
        this.$router.push(menu);
      }
    },
    details(menu) {
      sessionStorage.setItem("MENU", "公开信息披露");
      this.$router.push(menu);
    },
    details1(menu) {
      sessionStorage.setItem("MENU", "在线投保");
      this.$router.push(menu);
    },
    details2(menu) {
      sessionStorage.setItem("MENU", "关于我们");
      this.$router.push(menu);
    },
    jumpToRecruitment(menu) {
      if(menu == 'doing'){
        this.$message.info("系统升级中，敬请期待!");
        return
      }
      sessionStorage.setItem("MENU", "职业生涯");
      this.$router.push(menu);
    },
    jumpTo1(items) {
      console.log("item", items);
      // console.log(items.index)
      // if (sessionStorage.getItem("index")){
      //   if(items.categoryType){
      //     this.$router.push({
      //       name: `increaseWealth`,
      //       query: { categoryType: 1,productHotCategory:items.categoryShowindex -1 },
      //     });
      //   }else{
      //     this.$router.push({
      //       name: `increaseWealth`,
      //       query: {categoryType: 0,index:sessionStorage.getItem("index") },
      //     });
      //   }
      // }else{
      //   if(items.categoryType){
      //     this.$router.push({
      //       name: `increaseWealth`,
      //       query: { categoryType: 1,productHotCategory:items.categoryShowindex -1 },
      //     });
      //   }else{
      //     this.$router.push({
      //       name: `increaseWealth`,
      //       query: {categoryType: 0,index:items.index },
      //     });
      //   }
      // }
      // -----------
      if (items.categoryType) {
        if(items.categoryType=='2'){
          this.$router.push({
          name: `increaseWealth`,
          query: {
            categoryType: 2,
            index: items.index,
            productHotCategory: items.zid,
          },
        });
        }else{
          this.$router.push({
          name: `increaseWealth`,
          query: {
            categoryType: 1,
            // productHotCategory: items.categoryShowindex - 1,
            productHotCategory: items.zid,
          },
        });
        }
      } else {
        this.$router.push({
          name: `increaseWealth`,
          query: { categoryType: 0,  },
        });
      }
      // ----------------
      // this.current = menu;
      // this.$router.push(`/IncreaseWealth?index=${index}`);
      // this.$router.push({
      //   path: `/IncreaseWealth`,
      //   query: { index,categoryType: 0, },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
// 导航栏
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}

.box {
  position: relative;

  nav {
    width: 80vw;
    height: 10.2rem;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;

    > .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .nav_item {
        // width: 50vw;
        height: 10.2rem;
        font-size: 2.6rem;
        font-weight: 300;
        display: flex;
        height: 100%;
        // justify-content: space-between;
        > li:first-child {
          margin-left: 0;
        }

        > li {
          cursor: pointer;
          // width: 8rem;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          // height: 10rem;
          margin: 0 2rem;
          height: 100%;

          &:hover {
            // background-color: #003781;
            > span {
              // color: #fff;
              //border-bottom: 0.3rem solid #003781;
              color: #003781;
              font-weight: 700;
              text-decoration: none;
              border-bottom: 0.5rem solid #003781;
              padding-bottom: 0;
            }
          }

          > span {
            //height: 3rem;
            //display: flex;
            display: inline-block;
            position: relative;
            border-color: transparent;
            text-decoration: none;
            text-align: center;
            transition: font-weight 0.2s ease, color 0.2s ease;
            outline: 0;
            //font-size: 16px;
            line-height: 3rem;
            padding-bottom: 0.5rem;
            margin-bottom: -0.5rem;
          }

          /*二级菜单样式*/

          > .menu2 {
            /*让原位置消失 二级菜单显示时不影响下面的布局*/
            position: absolute;
            z-index: 999;
            width: 100%;
            // height: 20vw;
            background: #fff;
            right: 0;
            top: 10.2rem;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;

            // 产品中心
            > .menu2_product {
              width: 80vw;
              margin: auto;
              display: flex;
              justify-content: space-between;
              height: 40rem;
              >:nth-child(1){
                width: 60%;
              }
              >:nth-child(2){
                width: 32%;
              }
              >:nth-child(3){
                width: 48%;
              }
              > li {
                width: 48%;

                > ul {
                  display: flex;
                  flex-wrap: wrap;
                  //height: 12vw;
                  height: 28rem;
                  flex-direction: column;

                  > li {
                    // width: 33%;
                    box-sizing: border-box;
                    line-height: 5rem;
                    &:hover {
                      p:first-child {
                        // color: green;
                        color: #003781;
                      }
                    }

                    > p {
                      font-size: 1.5rem;
                    }

                    > p:last-child {
                      color: #006192;
                      margin-top: 0.7rem;
                    }
                  }
                }
              }
            }

            // 产品服务
            > .menu2_service {
              width: 80vw;
              margin: auto;
              display: flex;
              justify-content: space-between;
              // height: 27vw;
              > li {
                width: 25%;

                > ul {
                  display: flex;
                  flex-wrap: wrap;
                  //height: 10vw;
                  height: 23rem;
                  flex-direction: column;

                  > li {
                    //padding: 0.5rem;
                    box-sizing: border-box;
                    //margin: 5px 0;
                    line-height: 5rem;
                    a {
                      //> p {
                      font-size: 1.5rem;
                      //width: 3rem;
                      color: rgb(62, 62, 62);

                      &:hover {
                        color: #003781;
                      }

                      //}
                    }

                    > p {
                      font-size: 1.5rem;
                      //width: 3rem;
                      &:hover {
                        color: #003781;
                      }
                    }
                  }
                }
              }

              > li:nth-child(3) {
               // width: 20%;
              }
            }

            .menu2_product_title {
              display: flex;
              align-items: center;
              margin: 2rem 0;

              > img {
                width: 3rem;
                height: 3rem;
                margin-right: 2.7rem;
              }

              > a {
                font-size: 2rem;
                color: #003781;
                font-weight: 500;
              }
            }

            .menu2_about {
              width: 80vw;
              margin: auto;
              display: flex;
              // justify-content: space-between;
              // height: 27vw;
              > li {
                //width: 35%;

                > ul {
                  > li {
                    padding: 0.5rem;
                    box-sizing: border-box;
                    margin: 5px;
                    width: 27rem;
                    > p,span {
                      width: 70%;
                      font-size: 1.7rem;
                      &:hover {
                        color: #003781;
                        .treeMunu {
                            display: inline-block;
                        }
                      }
                    }
                    >.lineHeigth{
                    span{
                          font-size: 1.5rem;
                        }
                      width: 100%;
                      font-size: 1.4rem;
                      display: flex;
                      // line-height:61px;
                        &:hover{
                          .treeMunu {
                            display: inline-block;
                            font-size: 1.4rem;
                            margin: 1% 0 1% 1%;
                            >span &:hover{
                              color: #003781;
                            }
                          }
                          .lineHeigth2{
                            line-height: 73px;
                          }
                        }
                        > .treeMunu {
                          display: none;
                          text-align: right;
                          p{
                            margin: 10%;
                          }
                        }
                        .lineHeigth2{
                          span{
                            font-size: 1.7rem;
                          }
                        }
                      }
                    }
                  }
                }
              }

            > .about {
              // flex-direction: column;
              // flex-wrap: wrap;
              height: 100%;

              > li {
                //width: 20%;

                > .Level3 {
                  // height: 100%;
                  display: flex;
                  // flex-direction: column;
                  flex-wrap: wrap;
                }
              }

              > li:nth-child(3) {
                //width: 43%;
              }

              // > li:nth-child(5) {
              //   width: 33%;
              // }
              > .aboutFirst {
                height: 8rem;
              }
            }
          }

          /*隐藏二级菜单*/

          > .menu2 {
            display: none;
          }
        }

        /*移入一级菜单显示二级菜单*/

        > li {
          &:hover {
            > .menu2 {
              display: block;
              overflow: hidden;
            }
          }
          > .Level3{
              display: block;
              overflow: hidden;
          }

        }

        > li:first-child {
          &:hover {
            > .menu2 {
              animation: displayAbout 0.3s ease-in 0s forwards;
            }
          }
        }

        // 产品中心
        > li:nth-child(2) {
          &:hover {
            > .menu2 {
              animation: displayProduct 0.3s ease-in 0s forwards;
            }
          }
        }

        // 产品中心
        > li:nth-child(3) {
          &:hover {
            > .menu2 {
              animation: displayAbout 0.3s ease-in 0s forwards;
            }
          }
        }

        > li:nth-child(4) {
          &:hover {
            > .menu2 {
              animation: displayServiceChannel 0.3s ease-in 0s forwards;
            }
          }
        }

        // 新闻
        > li:nth-child(5) {
          &:hover {
            > .menu2 {
              animation: displayNew 0.3s ease-in 0s forwards;
            }
          }
        }

        > li:nth-child(6) {
          &:hover {
            > .menu2 {
              animation: displayServiceChannel 0.3s ease-in 0s forwards;
            }
          }
        }

        // 服务通道
        > li:nth-child(7) {
          &:hover {
            > .menu2 {
              animation: displayNew 0.3s ease-in 0s forwards;
            }
          }
        }

        .bg {
          background-color: #003781;
          color: #fff;
        }
      }

      // .menu2_box {
      //   height: 20vw;
      // }
    }

    // 新闻
    .menu2_news {
      width: 80vw;
      margin: auto;
      display: flex;
      height: 100%;
      flex-wrap: wrap;

      &.sell > li {
        width: calc(100% / 4);
      }

      > li {
        background: #fff;
        display: flex;
        align-items: center;
        width: 20%;
        height: 10rem;
        // height: 8vw;
        // padding: 0px 20px;
        // box-sizing: border-box;
        // width: 150px;
        img {
          width: 3rem;
          height: 3rem;
          margin-right: 2rem;
        }

        a {
          font-size: 1.85rem;
          color: #003781;
          font-weight: 500;
        }

        > div {
          display: flex;
          align-items: center;
        }
      }

      > li {
        &:hover {
          a {
            border-bottom: 0.3rem solid;
          }
        }
      }
    }

    .menu_two_news {
      > li {
        width: 25%;
      }
    }

    .insured {
      //li:nth-child(3),
      li:nth-child(4),
      li:last-child {
        //width: 13%;
      }
    }

    .information {
      // width: 16.25rem;
      // height: 16px;
      font-size: 2.6rem;
      display: flex;
      white-space: nowrap;
      justify-content: space-between;
      height: 100%;
      align-items: center;

      > li {
        height: 100%;
        display: flex;
        align-items: center;

        > span {
          cursor: pointer;
          //height: 3rem;
          display: inline-block;
          position: relative;
          border-color: transparent;
          text-decoration: none;
          text-align: center;
          transition: font-weight 0.2s ease, color 0.2s ease;
          outline: 0;
          //font-size: 16px;
          line-height: 3rem;
          padding-bottom: 0.5rem;
          margin-bottom: -0.5rem;
        }

        /*二级菜单样式*/

        > .menu2 {
          /*让原位置消失 二级菜单显示时不影响下面的布局*/
          position: absolute;
          z-index: 999;
          width: 100%;
          // height: 20vw;
          background: #fff;
          right: 0;
          top: 10.2rem;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          > .information_menu2 {
            width: 80vw;
            margin: auto;
            display: flex;
            .addWidth{
              width: 15%;
              margin-left: 7%;
            }
            .addWidthImg{
              width: 3rem;
              height: 3rem;
            }

            > li  {
             // width: 60%;

              > div {
                display: flex;
                margin: 2rem 0;
                // align-items: center;
                // justify-content: center;
                > span {
                  font-size: 1.85rem;
                  width: 15rem;
                  line-height: 4rem;
                  cursor: pointer;

                  &:hover {
                    color: #003781;
                  }
                }

                img {
                  width: 2.6rem;
                  height: 3rem;
                  margin-right: 2rem;
                }

                > a {
                  font-size: 2rem;
                  color: #003781;
                  font-weight: 500;
                  cursor: pointer;
                  height: 3rem;

                  &:hover {
                    border-bottom: 0.3rem solid #003781;
                  }
                }
              }

              .menu2_link {
                display: flex;
                flex-wrap: wrap;
                margin: 0;

                > .menu_two {
                  margin: 1rem 4rem 0 0;
                  width: 13rem;
                  white-space: nowrap;
                  font-size: 1.85rem;

                  > span {
                    cursor: pointer;
                    height: 3rem;

                    &:hover {
                      color: #003781;
                    }
                  }
                }
              }

              > .menu_two {
                > ul {
                  > li {
                    font-size: 1.5rem;
                    margin: 0 2rem 0 0;
                    height: 4rem;
                    line-height: 4rem;
                    color: #666;
                    cursor: pointer;

                    &:hover {
                      border-bottom: 0.3rem solid #003781;
                    }
                  }
                }
              }

              > .menu_two:nth-child(3) {
                > ul {
                  height: 16rem;
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                }
              }
            }

            > li:nth-child(4) {
              width: 25%;
              margin: 0 0 0 5rem;

              > .menu_two:nth-child(4) {
                margin-bottom: 0 !important;
                margin-top: 1rem;
              }

              > .menu_two:nth-child(3) {
                height: 7rem;
                margin-bottom: 0 !important;
                margin-top: 1rem;
              }

              > .menu_two:nth-child(4) {
                margin: 2rem 0 0 0;
              }
            }

            > li:nth-child(3) ,li:nth-child(2){
             // width: 15%;
              //margin-left: 7rem;
              // margin: 0 7rem;
              /*> div {
                width: 14rem;
                height: 20rem;
                background-color: #003781;
                justify-content: center;
                padding: 2rem;
                flex-direction: column;
                align-items: center;

                img {
                  width: 4rem;
                  height: 5rem;
                  margin: 0;
                }

                > a {
                  color: #fff;
                  margin: 2rem 0 0 0;
                  font-size: 2rem;
                  font-weight: 400;
                }
              }*/
            }
          }
        }

        > .disclosure {
          // height: 27vw;
          height: 29vw;
        }

        /*隐藏二级菜单*/

        > .menu2 {
          display: none;
        }
      }

      /*移入一级菜单显示二级菜单*/

      > li {
        &:hover {
          > span {
            //border-bottom: 0.3rem solid #003781;
            color: #003781;
            font-weight: 700;
            text-decoration: none;
            border-bottom: 0.5rem solid #003781;
            padding-bottom: 0;
          }

          > .menu2 {
            display: block;
            overflow: hidden;
          }
        }
      }

      > li:nth-child(1) {
        &:hover {
          > .menu2 {
            animation: displayDisclosure 0.3s ease-in 0s forwards;
          }
        }
      }
    }
  }

  .two_menu {
    display: none;
    width: 100%;
    height: 25vw;
    background: #fff;
    position: fixed;
    z-index: 1;
    background: #fff;

    > .menu {
      width: 80vw;
    }
  }

  @keyframes displayAbout {
    from {
      height: 0vw;
    }
    to {
      height: 38rem;
    }
  }
  @keyframes displayAbout1 {
    from {
      height: 0vw;
    }
    to {
      height: 50rem;
    }
  }
  @keyframes displayAbout2 {
    from {
      height: 0vw;
    }
    to {
      height: 50rem;
    }
  }
  @keyframes displayProduct {
    from {
      height: 0vw;
    }
    to {
      height: 35rem;
    }
  }
  @keyframes displayProduct1 {
    from {
      height: 0vw;
    }
    to {
      height: 60rem;
    }
  }
  @keyframes displayNew {
    from {
      height: 0vw;
    }
    to {
      height: 10vw;
    }
  }
  @keyframes displayServiceChannel {
    from {
      height: 0vw;
    }
    to {
      height: 100%;
    }
  }
  @keyframes displayDisclosure {
    from {
      height: 0vw;
    }
    to {
      //height: 27vw;
      height: 29vw;
    }
  }
  @keyframes displayDisclosure1 {
    from {
      height: 0vw;
    }
    to {
      height: 70rem;
    }
  }
  @keyframes displayServiceChannel {
    from {
      height: 0vw;
    }
    to {
      height: 100%;
    }
  }
}

//@media all and (min-width:1000px){
//  > .menu2 {
//    animation: displayProduct 0.3s ease-in 0s forwards;
//  }
//}
@media all and (min-width: 600px) and (max-width: 1000px) {
  //产品中心
  .box nav > .nav .nav_item > li:nth-child(2):hover > .menu2 {
    animation: displayProduct1 0.3s ease-in 0s forwards;
  }

  .box nav > .nav .nav_item > li > .menu2 > .menu2_product {
    width: 80vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 60rem;
  }

  .box nav > .nav .nav_item > li > .menu2 > .menu2_product > li > ul {
    display: flex;
    flex-wrap: wrap;
    height: 50rem;
    flex-direction: column;
  }

  .box nav > .nav .nav_item > li > .menu2 > .menu2_product > li:first-child {
    width: 60%;
  }

  .box nav > .nav .nav_item > li > .menu2 > .menu2_product > li:last-child {
    width: 40%;
  }

  //关于我们
  .box nav > .nav .nav_item > li:first-child:hover > .menu2 {
    animation: displayAbout1 0.3s ease-in 0s forwards;
  }

  //产品服务
  .box nav > .nav .nav_item > li:nth-child(3):hover > .menu2 {
    animation: displayAbout2 0.3s ease-in 0s forwards;
  }

  .box nav > .nav .nav_item > li > .menu2 > .menu2_service > li > ul {
    display: flex;
    flex-wrap: wrap;
    height: 50rem;
    flex-direction: column;
  }

  //信息披露
  .box nav .information > li:nth-child(1):hover > .menu2 {
    animation: displayDisclosure1 0.3s ease-in 0s forwards;
  }

  .box
    nav
    .information
    > li
    > .menu2
    > .information_menu2
    > li
    > .menu_two:nth-child(3)
    > ul {
    height: 35rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .box nav .information > li > .menu2 > .information_menu2 > li > div > span {
    font-size: 1.85rem;
    width: auto;
    line-height: 4rem;
    cursor: pointer;
  }

  .box
    nav
    .information
    > li
    > .menu2
    > .information_menu2
    > li
    > .menu_two
    > ul
    > li {
    font-size: 1.5rem;
    margin: 0.5rem 2rem;
    height: 4rem;
    line-height: 4rem;
    color: #666;
    cursor: pointer;
  }

  .box
    nav
    .information
    > li
    > .menu2
    > .information_menu2
    > li:nth-child(4)
    > .menu_two:nth-child(3) {
    height: 10rem;
    margin-bottom: 0 !important;
    margin-top: 1rem;
  }

  .box
    nav
    .information
    > li
    > .menu2
    > .information_menu2
    > li
    .menu2_link
    > .menu_two {
    margin: 2rem 4rem 0 0;
    width: 15rem;
    white-space: nowrap;
    font-size: 1.85rem;
  }

  .box
    nav
    .information
    > li
    > .menu2
    > .information_menu2
    > li:nth-child(3)
    > div {
    width: 18rem;
    //height: 27rem;
    //background-color: #003781;
    justify-content: center;
    //padding: 2rem;
    flex-direction: column;
    align-items: center;
  }

  //在线
  .box nav .menu2_news > li {
    background: #fff;
    display: flex;
    align-items: center;
    width: 25%;
    height: 10rem;
  }
}
</style>
