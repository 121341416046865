import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";
// import store from "@/main"

Vue.use(VueRouter);

const routes = [
  // 404
  {
    path: "/found404",
    name: "found404",
    component: () => import("@/views/found404"),
  },
  //  预览失效
  {
    path: "/failure",
    name: "failure",
    component: () => import("@/views/preview/found404"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/home",
    component: Home,
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Home"),
      },
      {
        // 网上销售平台
        path: "/azContent/onlineSales",
        name: "azContent/onlineSales",
        component: () => import("@/views/azContent/onlineSales"),
      },
      {
        //关于我们--富文本内容
        path: "/azContent/introduce/:id",
        name: "azContent/introduce",
        component: () => import("@/views/azContent/introduce"),
      },
      {
        //关于我们--富文本内容
        path: "/azContent/introduce/:id/:year",
        name: "azContent/introduce",
        component: () => import("@/views/azContent/introduce"),
      },
      {
        //关于我们--富文本内容
        path: "/azContent/particiInsurance/:id",
        name: "azContent/particiInsurance",
        component: () => import("@/views/azContent/particiInsurance"),
      },
      {
        //关于我们--富文本内容--预览
        path: "/azContent/preParticiInsurance/:id",
        name: "azContent/preParticiInsurance",
        component: () => import("@/views/azContent/preParticiInsurance"),
      },
      {
        // 新闻公告-累积生息利率及贷款利率公告-详情
        path: "/azContent/policyLoanRate",
        name: "azContent/policyLoanRate",
        component: () => import("@/views/azContent/policyLoanRate"),
      },
      {
        //TODO 预览---关于我们--富文本内容
        path: "/preview/azContentPreview/about/:id",
        name: "preview/azContentPreview/about",
        component: () => import("@/views/preview/about"),
        // component: () => import("@/views/azContent/introduce"),
      },
      {
        //TODO 预览---关于我们--富文本内容
        path: "/preview/azContentPreview/about/:id/:year",
        name: "preview/azContentPreview/about",
        component: () => import("@/views/preview/about"),
        // component: () => import("@/views/azContent/introduce"),
      },
      {
        // 产品中心--财富增值
        path: "/increaseWealth",
        name: "increaseWealth",
        component: () =>
          import("@/views/productCenter/personal/increaseWealth"),
      },
      {
        //TODO 预览---产品中心
        path: "/preview/productCenter/series",
        name: "preview/productCenter/series",
        component: () => import("@/views/preview/increaseWealth"),
      },
      {
        // 产品中心--财富增值-详情
        path: "/increaseWealth/increaseWealthDetails",
        name: "increaseWealth/increaseWealthDetails",
        component: () =>
          import("@/views/productCenter/personal/increaseWealthDetails"),
      },
      {
        //TODO 预览---产品中心-详情
        path: "/preview/productCenter/series/details",
        name: "preview/productCenter/series/details",
        component: () => import("@/views/preview/increaseWealthDetails"),
      },

      //  产品服务
      {
        // 保单售后
        path: "/personalCustomer/insuranceAfterSales/:id",
        name: "/personalCustomer/insuranceAfterSales",
        component: () =>
          import(
            "@/views/productsServices/personalCustomer/insuranceAfterSales"
          ),
      },
      {
        //TODO 预览---保单售后
        path: "/preview/productService/enquiryService/:id",
        name: "preview/productService/enquiryService",
        component: () => import("@/views/preview/insuranceAfterSales"),
      },
      {
        // 享健康
        path: "/productsServices/health",
        name: "productsServices/health",
        component: () => import("@/views/productsServices/health"),
      },
      {
        //TODO 预览---享健康
        path: "/preview/health/specialZone",
        name: "preview/health/specialZone",
        component: () => import("@/views/preview/health"),
      },
      {
        // 产品服务--会员权益俱乐部
        path: "/productsServices/vipZone",
        name: "productsServices/vipZone",
        component: () => import("@/views/productsServices/vipZone"),
      },
      {
        // 产品服务--会员权益俱乐部-详情
        path: "/productsServices/vipText/:id",
        name: "productsServices/vipText",
        component: () => import("@/views/productsServices/vipText"),
      },
      {
        //TODO 预览---产品服务--会员权益俱乐部-详情
        path: "/preview/member/rightsInterests/:id",
        name: "preview/member/rightsInterests",
        component: () => import("@/views/preview/vipText"),
      },
      {
        // 产品服务--在线自助-在线留言
        path: "/productsServices/selfHelp/online",
        name: "productsServices/selfHelp/online",
        component: () => import("@/views/productsServices/selfHelp/online"),
      },
      {
        // 新闻公告
        path: "/news/:id",
        name: "news",
        meta: {
          keepAlive: true,
          // scollTopPosition: 0
        },
        component: () => import("@/views/news/news"),
      },
      {
        //TODO 预览---新闻公告
        path: "/preview/news/announcement/:id",
        name: "preview/news/announcement",
        meta: {
          keepAlive: true,
          // scollTopPosition: 0
        },
        component: () => import("@/views/preview/news"),
      },
      {
        //TODO 预览---新闻公告-详情
        path: "/preview/details",
        name: "preview/details",
        // meta: {
        //   secTitle: "普通查询列表",
        //   keepAlive: true,
        // },
        component: () => import("@/views/preview/newsDetails"),
      },
      {
        // 新闻公告-详情
        path: "/newsDetails",
        name: "newsDetails",
        // meta: {
        //   secTitle: "普通查询列表",
        //   keepAlive: true,
        // },
        component: () => import("@/views/news/newsDetails"),
      },
      {
        // 新闻公告-信息披露
        path: "/disclosure",
        name: "disclosure",
        component: () => import("@/views/news/disclosure"),
      },
      {
        // TODO 预览---新闻公告-投连险信息披露
        path: "/preview/insurance/disclosure",
        name: "preview/insurance/disclosure",
        component: () => import("@/views/preview/disclosure"),
      },
      {
        // 新闻公告-信息披露
        path: "/disclosureDetails",
        name: "disclosureDetails",
        component: () => import("@/views/news/disclosureDetails"),
      },
      {
        // 新闻公告-万能险利率公告
        path: "/announcement",
        name: "announcement",
        component: () => import("@/views/news/announcement"),
      },
      {
        // 新闻公告-万能险利率公告
        path: "/interestrateHistory",
        name: "interestrateHistory",
        component: () => import("@/views/news/interestrateHistory"),
      },
      // 公开信息披露--下载
      {
        path: "/public/publicDisclosure/:id",
        name: "public/publicDisclosure",
        component: () => import("@/views/public/publicDisclosure"),
      },
      {
        path: "/public/publicDisclosureGy/:id",
        name: "public/publicDisclosureGy",
        component: () => import("@/views/public/publicDisclosureGy"),
      },
      //历史公告信息
      {
        path: "/public/historyVsersion/:parentId/:id",
        name: "public/historyVsersion",
        component: () => import("@/views/public/historyVsersion"),
      },
      //TODO 预览---公开信息披露--下载
      {
        path: "/preview/information/disclosure/:id",
        name: "preview/information/disclosure",
        component: () => import("@/views/preview/publicDisclosure"),
      },
      //历史记录预览
      {
        path: "/preview/information/historyVsersion/:parentId/:id",
        name: "preview/information/historyVsersion",
        component: () => import("@/views/preview/historyVsersion"),
      },
      // 公开信息披露--基本信息 > 公司概况 > 各分支机构营业场所和联系电话
      {
        path: "/public/contactNumber",
        name: "public/contactNumber",
        component: () => import("@/views/public/contactNumber"),
      },
      //TODO 预览公开信息披露--基本信息 > 公司概况 > 各分支机构营业场所和联系电话
      {
        path: "/preview/public/branchOffice",
        name: "preview/public/branchOffice",
        component: () => import("@/views/preview/contactNumber"),
      },
      // 信息披露详情
      {
        path: "/public/text",
        name: "public/text",
        component: () => import("@/views/public/text"),
      },
      // 偿付能力
      {
        path: "/solvencyCapacity/:id",
        name: "solvencyCapacity",
        component: () => import("@/views/solvencyCapacity/solvencyCapacity"),
      },
      //TODO 预览---偿付能力
      {
        path: "/preview/disclosure/solvency/:id",
        name: "preview/disclosure/solvency",
        component: () => import("@/views/preview/solvencyCapacity"),
      },
      // 消费者专栏
      {
        path: "/consumer",
        name: "consumer",
        component: () => import("@/views/consumer/consumer"),
      },
      //普惠金融推进月专栏
      {
        path: "/phFinancialPromotion",
        name: "phFinancialPromotion",
        component: () => import("@/views/consumer/phFinancialPromotion"),
      },
      //TODO 预览---消费者专栏
      {
        path: "/preview/consumer/riskWarning",
        name: "preview/consumer/riskWarning",
        component: () => import("@/views/preview/consumer"),
      },
      //TODO 预览---普惠金融推进
      {
        path: "/preview/phFinancialPromotion",
        name: "preview/phFinancialPromotion",
        component: () => import("@/views/preview/phFinancialPromotion"),
      },
      // 消费者专栏-详情
      {
        path: "/consumerDetails",
        name: "consumerDetails",
        component: () => import("@/views/consumer/consumerDetails"),
      },
      // 普惠金融推进月-详情
      {
        path: "/phFinancialPromotionDetails",
        name: "phFinancialPromotionDetails",
        component: () => import("@/views/consumer/phFinancialPromotionDetails"),
      },
      //TODO 预览---消费者专栏-详情
      {
        path: "/preview/consumer/riskWarning/details",
        name: "preview/consumer/riskWarning/details",
        component: () => import("@/views/preview/consumerDetails"),
      },
       //TODO 预览---普惠金融推进月-详情
      {
        path: "/preview/phFinancialPromotionDetails",
        name: "preview/phFinancialPromotionDetails",
        component: () => import("@/views/preview/phFinancialPromotionDetails"),
      },
      {
        // 个人养老金 正式页面
        path: "/azContent/personalPension",
        name: "azContent/personalPension",
        component: () => import("@/views/azContent/personalPension"),
      },
    ],
  },
  {
    path: "*", // 页面不存在的情况下会跳到404页面
    redirect: "/found404",
    hidden: true,
  },
  {
    path: "/olympicGames",
    name: "olympicGames",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/olympicGames.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    //添加该方法
    console.log(to, from, savedPosition);
    return { x: 0, y: 0 };
  },
});

//核心代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

export default router;
