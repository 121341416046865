<template>
  <interestrateMobileHistory v-if="isMobile">
  </interestrateMobileHistory>
  <div v-else class="interestrateHistory">
    <crumbs :item="title"></crumbs>
    <div class="wrap">
      <p>查询公布利率</p>
      <div class="tab">
        <div class="account">
          <span>账户类型：</span>
          <el-select v-model="countType" placeholder="请选择" @change="onChangeCountType">
            <el-option v-for="(item, index) in options" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="content">
          <vxe-table class="mytable-scrollbar" stripe border round align="center" ref="xTable" max-height="460" :loading="loading" :row-config="{ isHover: true }" :data="tableData">
            <vxe-column type="seq" width="60"></vxe-column>
            <vxe-column field="startday" title="利率始日"></vxe-column>
            <vxe-column field="endday" title="利率止日"></vxe-column>
            <vxe-column field="guaranterate" title="保证年利率(%)" sort-type="number" sortable></vxe-column>
            <vxe-column field="floatrate" title="浮动年利率(%)" sort-type="number" sortable></vxe-column>
            <vxe-column field="dayannualrate" title="月结算日利率(%)" sort-type="number" sortable></vxe-column>
            <vxe-column field="setannualrate" title="月结算年利率(%)" sort-type="number" sortable></vxe-column>
          </vxe-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import interestrateMobileHistory from "./mobile/interestrateHistoryMobile.vue";
import common from '@/utils/common'
import {
  getNewInterHistoryTable,
  getNewInterHistoryOption,
} from "@/api/newsnew/interestrateHistory";
export default {
  name: "interestrateHistory",
  data () {
    return {
      isMobile: false,
      title: [
        {
          title: "新闻公告",
          link: "",
        },
        {
          title: "万能险利率公告",
          link: "/announcement",
        },
        {
          title: "查询公布利率",
          link: "/interestrateHistory",
        },
      ],
      list: {
        title: "查询公布利率",
        iframe: "/ProductValueQuery/faces/search_rate_his.jspx",
      },
      url: "https://www.allianz.com.cn/ProductValueQuery/faces/rateNotice.jspx?inv_acc_id=43&cover=AYTL",
      count: 10,
      countType: "", //下拉框选中值
      options: [], //下拉选项
      tableData: [], //table数据
      loading: false, //加载
      wbFlag: false, //
    };
  },
  components: {
    crumbs,
    interestrateMobileHistory,
  },
  methods: {
    //下拉选项触发函数
    async onChangeCountType () {
      console.log(this.countType);
      await this.getNewInterHistoryTable(this.countType);
    },

    //获取下拉选项数据
    async getNewInterHistoryOption () {
      const [timeStamp, sign] = this.$getSign();
      let {
        data: { rows },
      } = await getNewInterHistoryOption({ timeStamp, sign });
      // this.options = rows.map(({ FID, ACCOUNT_NAME }) => ({
      // 	value: FID,
      // 	label: ACCOUNT_NAME,
      // }));
      let nowDate = new Date().getTime();
      if (nowDate >= new Date('2025-01-01').getTime()) {
        this.wbFlag = true
      }

      if (!this.wbFlag) {
        rows = rows.filter(item => item.FID != "AYHL58");
        console.log(rows, 'rows');
      }
      this.options = rows.map(({ FID, ACCOUNT_NAME }) => {
        return {
          value: FID,
          label: ACCOUNT_NAME,
        };
      });
    },

    //获取历史公布利率数据
    async getNewInterHistoryTable (fid) {
      this.loading = true;
      const [timeStamp, sign] = this.$getSign();
      const {
        data: { rows },
      } = await getNewInterHistoryTable({ timeStamp, sign, fid });
      this.tableData = rows.map((item, index) => {
        const {
          START_DATE,
          END_DATE,
          GRNT_RATE,
          FLOAT_RATE,
          DAY_RATE,
          INV_RATE,
        } = item;
        return {
          id: index,
          startday: START_DATE ? START_DATE.substring(0, 10) : "",
          endday: END_DATE ? END_DATE.substring(0, 10) : "",
          guaranterate: GRNT_RATE ? GRNT_RATE : "0",
          floatrate: FLOAT_RATE ? FLOAT_RATE : "0",
          dayannualrate: DAY_RATE ? DAY_RATE.toFixed(3) : "0",
          setannualrate: INV_RATE ? INV_RATE : "0",
        };
      });
      this.loading = false;
    },
  },
  async created () {
    this.countType = localStorage.getItem('interestrateHistoryCovercode');
    this.getNewInterHistoryTable(this.countType);
    this.isMobile = common.isMobile();
    var isPad = common.isPad();
    if (isPad) {
      this.isMobile = false
    }
    if (!this.isMobile) {
      await this.getNewInterHistoryOption(); //获取选项数据
    }
  },
};
</script>

<style lang="less" scoped>
.mytable-scrollbar ::-webkit-scrollbar {
  width: 5px;
}
.interestrateHistory {
  .wrap {
    width: 100%;

    > p {
      text-align: center;
      font-size: 4rem;
      margin: 4rem 0;
      color: #003781;
    }

    > .tab {
      //width: 60%;
      width: 148rem;
      background: #edeff5;
      margin: auto;
      padding: 0 5rem 5rem;
      // height: 52rem;
      margin-bottom: 6rem;

      //账户
      .account {
        padding: 20px 10px 20px 40px;
        font-size: 1.8rem;

        //select
        /deep/ .el-input__inner {
          width: 50rem;
          height: 3.5rem;
          line-height: 3.5rem;
        }

        //select-item
        /deep/ .el-input__suffix {
          .el-input__suffix-inner {
            .el-input__icon {
              line-height: 3.5rem;
            }
          }
        }
      }

      .pricingDay {
        padding: 10px 10px 10px 40px;
        font-size: 1.8rem;
      }

      .productName {
        display: flex;
        align-items: center;
        padding: 0 2rem;

        > div {
          margin: 1rem 3rem;
        }
      }

      > .table {
        width: 90%;
        margin: auto;
      }
    }
  }

  .history_title {
    width: 900px;
    margin: 0 auto 0rem;

    .type_sec {
      background: #edeff5;
      padding-bottom: 10px;
      padding: 10px 32px 10px 50px;
      font-size: 1.8rem;

      /deep/ .el-input__inner {
        width: 40rem;
        height: 3.5rem;
        line-height: 3.5rem;
      }

      /deep/ .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            line-height: 3.5rem;
          }
        }
      }
    }

    .table_style {
      border-collapse: collapse;
      width: 100%;

      .title {
        background: #edeff5;

        .title_color {
          color: #003781;
        }
      }

      tr {
        height: 4rem;
        line-height: 4rem;

        td {
          font-family: 方正正中黑简体;
          font-size: 1.8rem;
          width: 150px;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }

  .history_Info {
    overflow-y: scroll;
    padding-bottom: 50px;
    width: 900px;
    margin: 0 auto 0rem;
    height: 25vw;
    /* border: 1px solid; */
    display: flex;
    justify-content: center;

    .table_style {
      border-collapse: collapse;
      width: 100%;

      .title {
        background: #edeff5;

        .title_color {
          color: #003781;
        }
      }

      // tbody {
      //   border-bottom: 1px solid #3e3e3e;
      // }
      tr {
        height: 3.5rem;
        line-height: 3.5rem;

        td {
          font-family: 方正正中黑简体;
          color: #3e3e3e;
          font-size: 1.8rem;
          width: 150px;
          text-align: center;
          font-weight: 400;
          border-bottom: unset;
        }
      }

      .loading_style {
        margin-top: 15px;
        font-size: 2rem;
      }
    }
  }
}
</style>
