<template>
  <MobileAnnounceContainer v-if="isMobile" :contentIntro="contentIntro" :announceTime="announceTime"></MobileAnnounceContainer>
  <div class="announcement" v-else>
    <crumbs :item="title"></crumbs>
    <div class="wrap">
      <div class="box" v-for="(item, index) in contentIntro" :key="index">
        <!-- <p>{{ item.title }}</p> -->
				<span v-show='item.code!="AYHL58"||wbFlag'>
        <p v-html="item.title"></p>
        <table border="1px solid #ccc" cellspacing="0" cellpadding="0" class="table_style">
          <tbody>
            <tr>
              <td colspan="5">◎每月宣告利率</td>
            </tr>
            <tr>
              <td colspan="5">
                <span style="color: #003781">公布时间:&nbsp;&nbsp;</span><span>{{ item.time }} </span>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>最低保证年利率</td>
              <td>月初浮动年利率</td>
              <td>月结算年利率</td>
              <td>月结算日利率</td>
            </tr>
            <tr v-for="(rateitem, rateindex) in item.rate" :key="rateindex">
              <td>{{ rateitem.TERM }}</td>
              <td>{{ rateitem.GRNT_RATE }}</td>
              <td>{{ rateitem.FLOAT_RATE }}</td>
              <td>{{ rateitem.INV_RATE }}</td>
              <td>{{ rateitem.DAY_RATE }}</td>
            </tr>
            <!-- ◎保单持续奖金 -->
            <tr v-if="
								item.code == 'AYTL59' ||
								item.code == 'SSWY43' ||
								item.code == 'AYHL44' ||
								item.code == 'AYHL43' ||
								item.code == 'AYTL51' ||
								item.code == 'AYTL52' ||
								item.code == 'AYTL56' ||
								item.code == 'AYTL57' ||
								item.code == 'AYHL58'
							">
              <td colspan="2">◎保单持续奖金</td>
              <td colspan="3">{{ item.contPolicy }}</td>
            </tr>
            <!-- ◎持续奖金率 -->
            <tr v-if="
								item.code == 'YYLC28' ||
								item.code == 'JYSJ13' ||
								item.code == 'FYRS13' ||
								item.code == 'LHLC13' ||
								item.code == 'DYJ13'
							">
              <td colspan="2">◎持续奖金率</td>
              <td colspan="3">{{ item.contPolicyRate }}</td>
            </tr>
            <!-- ◎当时市场利率 -->
            <tr v-if="
								item.code == 'YYLC28' ||
								item.code == 'JYSJ13' ||
								item.code == 'FYRS13' ||
								item.code == 'LHLC13' ||
								item.code == 'DYJ13'
							">
              <td colspan="2">◎当时市场利率</td>
              <td colspan="3">{{ item.currentRate }}</td>
            </tr>
            <tr>
              <td colspan="5" style="text-align: left; padding: 10px" v-html="item.text"></td>
            </tr>
          </tbody>
        </table>
        <div class="historyBtn" @click="history(item.code)">历史利率查询</div>
			</span>
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import { getNewAnnouce, getNewPerstst } from "@/api/newsnew/announcement.js";
import $ from "jquery";
import common from '@/utils/common'
import MobileAnnounceContainer from './mobile/announcementMobile.vue'
let contentIntro = [
  {
    code: "AYTL59",
    accId: "59",
    contPolicy: "1%", //@◎保单持续奖金
    title: "安联安盈添利终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
    rate: [],
    time: "",
  },
  {
    code: "SSWY43",
    accId: "43",
    contPolicy: "3%", //@◎保单持续奖金
    title: "安联盛世稳盈年金保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
    rate: [],
    time: "",
  },
  {
    code: "AYWL42",
    accId: "42",
    title: "安联安盈稳利两全保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "YYLC28",
    accId: "28",
    contPolicyRate: "0", //@◎持续奖金率
    currentRate: "3%", //@◎当前市场利率
    title: "安联优越理财终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用。",
    rate: [],
    time: "",
  },
  {
    code: "AYHL44",
    accId: "44",
    contPolicy: "3%", //@◎保单持续奖金
    title: "安联安盈恒利两全保险（万能型）（2021年9月1日后生效的保单）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
    rate: [],
    time: "",
  },
  {
    code: "AYHL43",
    accId: "43",
    contPolicy: "3%", //@◎保单持续奖金
    title: "安联安盈恒利两全保险（万能型）（2021年9月1日前生效的保单）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至本合同约定的满期日且本合同仍然有效，我们在满期日按照当时趸交保险费余额的 3%发放持续奖金。",
    rate: [],
    time: "",
  },
  {
    code: "JYSJ13",
    accId: "13",
    title: "联众金赢世家终身寿险（万能型）",
    contPolicyRate: "0", //@◎持续奖金率
    currentRate: "3%", //@◎当前市场利率
    text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>持续奖金分别于第4、6、8、10个保单年度末根据当时保单帐户价值计算。",
    rate: [],
    time: "",
  },
  {
    code: "FYRS13",
    accId: "13",
    title: "联众福佑人生终身寿险（万能型）",
    contPolicyRate: "0", //@◎持续奖金率
    currentRate: "3%", //@◎当前市场利率
    text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>原有三年期帐户于2008年3月5日起更名为“特A十年期理财帐户”,使用十年期帐户宣告利率；<br/>原有七年期帐户于2008年3月5日起更名为“特B十年期理财帐户”,使用十年期帐户宣告利率。",
    rate: [],
    time: "",
  },
  {
    code: "LHLC13",
    accId: "13",
    title: "联众灵活理财终身寿险（万能型）",
    contPolicyRate: "0", //@◎持续奖金率
    currentRate: "3%", //@◎当前市场利率
    text: "结算年利率为最低保证年利率与浮动年利率之和；<br/>以上利率为年利率。<br/>“当时市场利率”适用于计算理财账户退保费用；<br/>原有三年期帐户于2008年3月5日起更名为“特A十年期理财帐户”,使用十年期帐户宣告利率；<br/>原有七年期帐户于2008年3月5日起更名为“特B十年期理财帐户”,使用十年期帐户宣告利率。",
    rate: [],
    time: "",
  },
  {
    code: "GUVL0113",
    accId: "13",
    title: "联众团体年金保险（万能型）（2005）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "GUVL0213",
    accId: "13",
    title: "安联团体年金保险（万能型）（2007）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "GUVL0313",
    accId: "13",
    title: "安联团体年金保险（万能型）（2008）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "GUVL0445",
    accId: "45",
    title: "安联团体（2023）年金保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "DYJ13",
    accId: "13",
    contPolicyRate: "0", //@◎持续奖金率
    currentRate: "3%", //@◎当前市场利率
    title: "联众大赢家终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    ttime: "",
  },
  {
    code: "GUVL0548",
    accId: "48",
    title: "安联团体（2024）年金保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "AYTL51",
    accId: "51",
    contPolicy: "1%", //@◎保单持续奖金
    title: "安联安盈添利（II）终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
    rate: [],
    time: "",
  },
  {
    code: "AYTL52",
    accId: "52",
    contPolicy: "1%", //@◎保单持续奖金
    title: "安联安盈添利A（II）终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
    rate: [],
    time: "",
  },
  {
    code: "GUVLC0553",
    accId: "53",
    title: "安联团体C（2024）年金保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。",
    rate: [],
    time: "",
  },
  {
    code: "AYTL56",
    accId: "56",
    contPolicy: "1%", //@◎保单持续奖金
    title: "安联安盈添利C（II）终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
    rate: [],
    time: "",
  },
  {
    code: "AYTL57",
    accId: "57",
    contPolicy: "1%", //@◎保单持续奖金
    title: "安联安盈添利（III）终身寿险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第五个保单周年日且本合同仍然有效，我们在第五个保单周年日按照前五个保单年度转入保险费之和（不含第五个保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。<br/>若被保险人在第六个保单周年日（含）起的每个保单周年日仍然生存且本合同有效，我们在每个保单周年日按照该保单周年日前一个保单年度转入保险费之和（不含该保单周年日转入的转入保险费）的1%发放持续奖金，并记入保单账户。",
    rate: [],
    time: "",
  },
  {

    code: "AYHL58",
    accId: "58",
    contPolicy: "5%", //@◎保单持续奖金
    title: "安联安盈恒利（II）两全保险（万能型）",
    text: "结算年利率为最低保证年利率与浮动年利率之和；以上利率为年利率。<br/>保单持续奖金：若被保险人生存至第六个保单年度末且本合同仍然有效，我们按照第六个保单年度末本合同的保单账户价值扣除追加保险费余额后的5%发放一次性保单持续奖金，并计入保单账户。",
    rate: [],
    time: "",
  },
];

export default {
  name: "announcement",
  data () {
    return {
      isMobile: false,
      isShow: false,
      wbFlag: false,
      title: [
        {
          title: "新闻公告",
          link: "",
        },
        {
          title: "万能险利率公告",
          link: "/announcement",
        },
      ],
      url: "https://www.allianz.com.cn/ProductValueQuery/faces/rateNotice.jspx?inv_acc_id=43&cover=AYTL",
      contentIntro, //每个tab的数据
      announceTime: "", //公布日
    };
  },
  components: {
    crumbs,
    MobileAnnounceContainer,
  },
  methods: {
    //跳转到详细页面
    history (covercode) {
      this.$router.push({
        name: "interestrateHistory",
        params: {
          countType: covercode,
        },
      });
      localStorage.setItem("interestrateHistoryCovercode", covercode);
    },

    //接口
    async getNewAnnouceAll () {
      const [timeStamp, sign] = this.$getSign();
      //contPolicyRate持续奖金率
      const {
        data: {
          data: { PERSIST },
        },
      } = await getNewPerstst({ timeStamp, sign });
      this.contentIntro.map((item) => {
        if (item.contPolicyRate) {
          item.contPolicyRate = `${PERSIST}%`;
        }
      });

      //5年 10年的利率值
      const promises = ["43", "42", "28", "44", "13", "45", "48", "51", "52", "53", "56", "57", "58","59"].map((accId) =>
        getNewAnnouce({ timeStamp, sign, accId })
      );
      const results = await Promise.all(promises);
      const [announce43, announce42, announce28, announce44, announce13, announce45, announce48, announce51, announce52, announce53, announce54, announce57, announce58,announce59] =
        results;
				console.log('contentIntro ',contentIntro);
      this.contentIntro.map((item) => {
        //@安盈添利终身寿险（万能型）
        if (item.code == "AYTL59") {
			 announce59.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '59') {
					const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					// announce59.data.rows[1];
					//this.announceTime = NEW_DATE //@统一修改公布日时间
					item.time = NEW_DATE;
					item.rate.push({
					  //表格数据
					  TERM: ``,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})

          //@盛世稳盈年金保险（万能型）     安盈恒利两全保险（万能型）（2021年9月1日前生效的保单）
        } else if (item.code == "SSWY43" || item.code == "AYHL43") {
			announce43.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '43') {
					const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce43.data.rows[1];
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: `${TERM}年`,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})

          //@安盈稳利两全保险（万能型）
        } else if (item.code == "AYWL42") {
			announce42.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '42') {
					const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce42.data.rows[1];
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: `${TERM}年`,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})

          //@优越理财终身寿险（万能型）
        } else if (item.code == "YYLC28") {
			announce28.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '28') {
					const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce28.data.rows[0];
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: `${TERM}年`,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})
          //@安盈恒利两全保险（万能型）（2021年9月1日后生效的保单）
        } else if (item.code == "AYHL44") {
			announce44.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '44') {
					const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce44.data.rows[1];
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: `${TERM}年`,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})

          //@金赢世家终身寿险（万能型）   联众团体年金保险（万能型）（2005）   安联团体年金保险（万能型）（2007）   安联团体年金保险（万能型）（2008）   联众大赢家终身寿险（万能型）
        } else if (
          item.code == "JYSJ13" ||
          item.code == "GUVL0113" ||
          item.code == "GUVL0213" ||
          item.code == "GUVL0313" ||
          item.code == "DYJ13"
        ) {
			announce13.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '13') {
					const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce13.data.rows[1];
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: `${TERM}年`,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})

          //@福佑人生终身寿险（万能型）  灵活理财终身寿险（万能型）
        } else if (item.code == "LHLC13" || item.code == "FYRS13") {
          announce13.data.rows.map((itemdetail) => {
            const { NEW_DATE, TERM, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } =
              itemdetail;
            item.time = NEW_DATE
            item.rate.push({
              //表格数据
              TERM: `${TERM}年`,
              GRNT_RATE: `${GRNT_RATE}%`,
              FLOAT_RATE: `${FLOAT_RATE}%`,
              INV_RATE: `${INV_RATE}%`,
              DAY_RATE: `${DAY_RATE}%`,
            });
          });
        }
        //@安联团体（2023）年金保险（万能型）     
        else if (item.code == "GUVL0445") {
			announce45.data.rows.forEach(element => {
				if (element.INV_ACC_ID == '45') {
					const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element ;
					//   announce45.data.rows[1];
					//this.announceTime = NEW_DATE //@统一修改公布日时间
					item.time = NEW_DATE
					item.rate.push({
					  //表格数据
					  TERM: ``,
					  GRNT_RATE: `${GRNT_RATE}%`,
					  FLOAT_RATE: `${FLOAT_RATE}%`,
					  INV_RATE: `${INV_RATE}%`,
					  DAY_RATE: `${DAY_RATE}%`,
					});
				}
			})


        } else if (item.code == "GUVL0548") {
          announce48.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '48') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element;
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          });
        } else if (item.code == "AYTL51") {
          announce51.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '51') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          })
        } else if (item.code == "AYTL52") {
          announce52.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '52') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          })
        } else if (item.code == "GUVLC0553") {
          announce53.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '53') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          })
        } else if (item.code == "AYTL56") {
          announce54.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '56') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          })
        } else if (item.code == "AYTL57") {
          announce57.data.rows.forEach(element => {
            if (element.INV_ACC_ID == '57') {
              const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
              //this.announceTime = NEW_DATE //@统一修改公布日时间
              item.time = NEW_DATE
              item.rate.push({
                //表格数据
                TERM: ``,
                GRNT_RATE: `${GRNT_RATE}%`,
                FLOAT_RATE: `${FLOAT_RATE}%`,
                INV_RATE: `${INV_RATE}%`,
                DAY_RATE: `${DAY_RATE}%`,
              });
            }
          })
        } else if (item.code == "AYHL58") {
            announce58.data.rows.forEach(element => {
              if (element.INV_ACC_ID == '58') {
                const { NEW_DATE, GRNT_RATE, FLOAT_RATE, INV_RATE, DAY_RATE } = element
                //this.announceTime = NEW_DATE //@统一修改公布日时间
                item.time = NEW_DATE
                item.rate.push({
                  //表格数据
                  TERM: ``,
                  GRNT_RATE: `${GRNT_RATE}%`,
                  FLOAT_RATE: `${FLOAT_RATE}%`,
                  INV_RATE: `${INV_RATE}%`,
                  DAY_RATE: `${DAY_RATE}%`,
                });
              }
            })
        }
      });
    },
  },
  created () {
    this.isMobile = common.isMobile();
    var isPad = common.isPad();
    if (isPad) {
      console.log(isPad);
      this.isMobile = false
    }
    console.log(isPad);
    console.log(this.isMobile);
    if (this.isMobile) {
      $("body").css("overflow", "hidden");
    }
    this.getNewAnnouceAll(); //获取所有的表格
    //暂定在 2024年的10月1日之后显示
    let nowDate = new Date().getTime();
    if (nowDate >= 1727712000000) {
      this.isShow = true
    }
    if(nowDate >=new Date('2025-01-01').getTime()){
      this.wbFlag = true
    }
    console.log(new Date('2025-01-01').getTime(),'new Date');
    
  },
};
</script>

<style lang="less" scoped>
.announcement {
  .wrap {
    width: 100%;

    .box {
      width: 55%;
      margin: auto;
      padding: 2rem 0;

      //height: 80vw;
      //border: 1px solid;
      p {
        font-size: 2.5rem;
        color: #003781;
        // width: 70%;
        margin: 0rem auto 2rem;
        text-align: center;
      }

      .iframe {
        //width: 70%;
        width: 600px;
        margin: 0 auto 0rem;
        //height: 25vw;
        /* border: 1px solid; */
        display: flex;
        justify-content: center;

        /deep/ .x19 {
          margin: auto;
        }

        /deep/ .xdk {
          text-align: center !important;
        }
      }
    }
  }
}

::v-deep .x19 {
  margin: auto;
}

::v-deep .xhh {
  width: 580px;
  display: block;
  margin: auto;
}

.table_style {
  border-collapse: collapse;
  width: 100%;

  tr {
    height: 3.5rem;

    td {
      font-family: 方正正中黑简体;
      color: #3e3e3e;
      font-size: 1.8rem;
      width: 20%;
      text-align: center;
      border: 1px solid #3e3e3e;
      font-weight: 400;
    }
  }
}

.historyBtn {
  font-size: 2rem;
  color: #72007c;
  padding-top: 2rem;
  width: 80%;
  cursor: pointer;
}
</style>
