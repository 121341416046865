import "normalize.css";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import md5 from "js-md5";
import $ from "jquery";
import "vant/lib/button/style";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
// import "default-passive-events";
window.jQuery = $;
window.$ = $;

import {
	Carousel,
	CarouselItem,
	Button,
	Breadcrumb,
	BreadcrumbItem,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Tabs,
	TabPane,
	Select,
	Option,
	Message,
	Table,
	TableColumn,
	Input,
	Loading,
	Tooltip,
	Form,
	FormItem,
	RadioGroup,
	RadioButton,
	Row,
	Col,
	InfiniteScroll,
	DatePicker,
	MessageBox,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// import VScaleScreen from "v-scale-screen";
// Vue.use(VScaleScreen);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Button);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Row);
Vue.use(Col);
Vue.use(InfiniteScroll);
Vue.use(DatePicker);



// 按需引入
// 按需引入
import { NavBar, CountDown, Tabbar, TabbarItem, Cell, CellGroup, Field, Icon, Toast, DatetimePicker, Popup, Picker, Dialog, Swipe, SwipeItem, Lazyload, Tab, Grid, GridItem, Checkbox, CheckboxGroup, Uploader, Panel, Divider, Search, Image, Sidebar, SidebarItem, Rate, Sku, Stepper, AddressList, GoodsAction, GoodsActionIcon, GoodsActionButton, AddressEdit, List, Sticky, Skeleton, Area, Tag, Step, Steps, SwitchCell, Card, SubmitBar, ActionSheet, Switch,  Radio, CollapseItem, Overlay, IndexBar, IndexAnchor, PullRefresh, Popover ,Cascader ,NoticeBar,Badge, Circle,TreeSelect} from 'vant';
Vue.use(NavBar).use(CountDown).use(Tabbar).use(TabbarItem).use(Cell).use(CellGroup).use(Field).use(Icon).use(Toast).use(DatetimePicker).use(Popup).use(Picker).use(Dialog).use(Swipe).use(SwipeItem).use(Lazyload).use(Tab).use(Grid).use(GridItem).use(Checkbox).use(CheckboxGroup).use(Uploader).use(Panel).use(Divider).use(Search).use(Image).use(Sidebar).use(SidebarItem).use(Rate).use(Sku).use(Stepper).use(AddressList).use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton).use(AddressEdit).use(List).use(Sticky).use(Skeleton).use(Area).use(Tag).use(Step).use(Steps).use(SwitchCell).use(Card).use(SubmitBar).use(ActionSheet).use(Switch).use(Radio).use(CollapseItem).use(Overlay).use(IndexBar).use(IndexAnchor).use(PullRefresh).use(Popover).use(Cascader).use(NoticeBar).use(Badge).use(Circle).use(TreeSelect);

Vue.use(VXETable);
Vue.config.productionTip = false;

Vue.prototype.$md5 = md5;
Vue.prototype.$message = Message;
Vue.prototype.$MessageBox  = MessageBox;
Vue.prototype.$getSign = () => {
	const timeStamp = parseInt(new Date().getTime() / 1000) + "";
	const sign = md5(
		`timeStamp=${timeStamp}&appid=ffadac099ec8204c&appsecret=51612E169ACA177F`
	);
	return [timeStamp, sign];
};
Vue.background = false;
// router.beforeEach((to,from,next)=>{
//   if(from.path == '/increaseWealthDetails'){
//     localStorage.setItem('routerParams',JSON.stringify(to.params))
//     next()
//   }
//   next()
// })

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
