<template>
  <div>
    <div class="mobile-disclosure">
      <header>
        <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
      </header>
      <crumbs :item="title"></crumbs>
      <div class="main-content">
        <div class="banner_box">
          <!-- <div v-for="item in banner" :key="item.title"></div> -->
          <el-carousel :interval="5000" arrow="always" :autoplay="false" trigger="click" @change="bannerChange" :initial-index="id">
            <el-carousel-item v-for="item in list" :key="item.categoryName">
              <!-- <h3>{{ item }}</h3> -->
              <div class="pic" :class="backgroundClass(item.categoryText)">
                <div class="banner_title">{{ item.categoryName }}</div>
                <!-- <p>{{ item.text1 }}</p> -->
                <div>
                  {{ item.categoryText }}
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="tabs">
          <div class="search">
            <el-input clearable v-model="value" placeholder="输入关键字搜索"></el-input>
            <img @click="submit" src="~@/static/images/search.png" alt="" />
          </div>
        </div>
        <main
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-background="rgba(181, 218, 230, 0.8)"
    >
      <ul class="main">
        <template v-if="length !== 0">
          <li
            v-for="(item, index) in plan"
            :key="index"
            @click="details(item.zid)"
          >
            <img :src="item.productImage" alt="" />
            <div>
              <p>{{ item.productTitle }}</p>
              <div class="main_box">
                <ul class="introduce">
                  <li v-html="item.productSummary"></li>
                </ul>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <empty></empty>
        </template>
      </ul>
  
      <div class="paging">
        <p>共有{{ total }}条记录</p>
        <div>
          <img
            @click="getPageNumAReduction()"
            src="~@/static/images/l_arrow@2x.png"
            alt=""
          />
          <span>{{ length === 0 ? 0 : pageNum }}/{{ pages }}</span>
          <img
            @click="getPageNumAdd()"
            src="~@/static/images/ic_arrow_black@2x.png"
            alt=""
          />
        </div>
      </div>
    </main>
      </div>
      <div class="bottom">
        <div class="qrcode">
          <div class="item">
            <img src="~@/static/images/public.jpg" alt="" />
            <span>公众号：安联人寿</span>
          </div>
          <div class="item">
            <img src="~@/static/images/service.jpg" alt="" />
            <span>服务号-安联在线</span>
          </div>
        </div>
        <div class="info">
          <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
          <p class="beian">沪ICP备07011309号-1</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { productList, getProductCategory } from "@/api/personal";
import crumbs from "@/components/crumbs.vue";
import common from '@/utils/common'
export default {
  name: "increaseWealthMobile",
  props: {
  },
  data () {
    return {
      title: null,
      loading: false,
      fullscreenLoading: false,
      plan: null,
      length: null,
      activeName: "all",
      productChannel: [],
      total: 0,
      pageNum: 0,
      pages: 0,
      tab: "",
      value: "",
      list: [],
      id: 0,
      zid: 0,
      categoryType: null,
      productHotCategory: null,
      hotZid: 0,
      hotId: 0,
    };
  },
  components: {
    crumbs,
  },
  created () {
    this.isMobile = common.isMobile();
    var isPad = common.isPad();
    if (isPad) {
      this.isMobile = false
    }
    // this.loading = true;
    if (sessionStorage.getItem("increaseWealthInfo")) {
      //如果有就读取缓存里面的数据
      this.pageNum = sessionStorage.getItem("pageNum");
      // sessionStorage.removeItem("increaseWealthInfo");
    } else {
      this.pageNum = 1;
      //这个主要是从其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("pageNum");
    }
    console.log("传过来的zid", this.$route.query.productHotCategory);
    this.hotZid = this.$route.query.productHotCategory;

    this.categoryType = Number(this.$route.query.categoryType);
    console.log("id", this.$route.query.index);
    this.id = Number(this.$route.query.index || this.id);
    console.log("categoryType", this.$route.query.categoryType);
  },
  computed: {
    // 计算属性用于确定背景颜色的类名

  },
  mounted () {
    console.log("mounted", this.categoryType);
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }, 0);

    this.bannerList(this.categoryType);
    this.productHotCategory = this.$route.query.productHotCategory;
    console.log(this.productHotCategory);
    // 由于在mounted钩子中，$el可能还没有被完全渲染，你可能需要使用nextTick
  },

  methods: {
    // 方法用于获取div中的文本长度
    backgroundClass (text) {
      if (!text) return ''; // 如果没有文本，不添加任何类
      if (text.length > 100) {
        return 'longText';
      } else {
        return 'shortText';
      }
    },
    goTop () {
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    },
    // 下一页
    getPageNumAdd () {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
        this.goTop();
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction () {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
        this.goTop();
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },
    openFullScreen1 () {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
    bannerChange (e) {
      this.openFullScreen1();
      console.log("00", e);
      this.id = e;
      if (this.productHotCategory) {
        this.hotZid = this.list[this.id].zid;
      }
      sessionStorage.setItem("index", this.id); //存zid
      // this.fullscreenLoading = true;
      // this.productHotCategory
      //     ? this.$router.replace({
      //       path: `/increaseWealth`,
      //       query: {
      //         productHotCategory: this.id,
      //         categoryType: this.categoryType,
      //       },
      //     })
      //     : this.$router.replace({
      //       path: `/increaseWealth`,
      //       query: {index: this.id, categoryType: this.categoryType},
      //     });
      console.log(this.hotZid);
      // this.productHotCategory
      //   ? this.requestHot(this.pageNum, this.list[this.id].zid)
      //   : this.requestList(this.pageNum, this.list[this.id].zid);
      if (this.productHotCategory) {
        this.requestHot(this.pageNum, this.list[this.id].zid);
        history.pushState(
          {},
          "",
          `/increaseWealth?categoryType=1&productHotCategory=${this.hotZid}`
        );
      } else {
        this.requestList(this.pageNum, this.list[this.id].zid);
        history.pushState(
          {},
          "",
          `/increaseWealth?categoryType=0&index=${this.id}`
        );
      }
      console.log("e", this.id);
    },
    submit () {
      console.log(this.value);
      this.productHotCategory
        ? this.request1(this.pageNum, this.value)
        : this.request(this.pageNum, this.value);
    },

    async bannerList (num) {
      // this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getProductCategory({
          timeStamp,
          sign,
          pageSize: 6,
          categoryType: num,
        });
        console.log("财富增值List", res.data);
        if (res.data.code == 200) {
          console.log("cs", res.data);
          this.list = res.data.data;
          // this.zid = this.list[this.id].zid;
          if (!this.productHotCategory) {
            this.zid = this.list[this.id].zid;
            console.log("this.list", this.list[this.id].zid);
          } else {
            this.zid = this.hotZid;
          }
          console.log(this.zid);
          if (this.productHotCategory) {
            this.id = this.list.findIndex((item) => {
              console.log(item);
              return item.zid == this.hotZid;
            });
          }
          console.log("this.id", this.id);
          this.id = Number(this.$route.query.index || this.id);
          let titFlag
          if (this.categoryType == 0) {
            titFlag = '个人保险'
          } else if (this.categoryType == 2) {
            titFlag = '团体保险'
          } else {
            titFlag = '热销新品'
          }
          // console.log("this.list", this.list[this.id].zid);
          this.title = [
            {
              title: "产品中心",
              link: `/increaseWealth?index=0&categoryType=0`,
            },
            {

              title: titFlag,
              link: this.productHotCategory
                ? `/increaseWealth?productHotCategory=${this.hotZid}&categoryType=${this.categoryType}`
                : `/increaseWealth?index=0&categoryType=${this.categoryType}`,
            },
            {
              title: this.list[this.id].categoryName,
              link: this.productHotCategory
                ? `/increaseWealth?productHotCategory=${this.hotZid}&categoryType=${this.categoryType}`
                : `/increaseWealth?index=${this.id}&categoryType=${this.categoryType}`,
            },
          ];
        }
        console.log(this.productHotCategory);
        this.productHotCategory
          ? this.requestHot(this.pageNum, this.hotZid)
          : this.requestList(this.pageNum, this.list[this.id].zid);
      } catch (error) {
        console.log(error);
      }
      // this.loading = false;
    },
    //个人
    async request (num, title) {

      console.log("zid", this.zid);
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productList({
          timeStamp,
          sign,
          pageSize: 6,
          productCategory: this.zid,
          productHot: 0,
          pageNum: num,
          productTitle: title,
          // productHotCategory:0
        });
        console.log("财富增值", res.data);
        if (res.data.code == 200) {
          console.log(res);
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          // sessionStorage.removeItem("increaseWealthInfo");

          // this.plan.forEach((item) => {
          //   if (item.productHot === true) {
          //     this.data.push(item);
          //   }
          // });
          // console.log("data", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async requestList (num, zid, title) {
      console.log("zid", this.zid);
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productList({
          timeStamp,
          sign,
          pageSize: 6,
          productCategory: zid,
          productHot: 0,
          pageNum: num,
          productTitle: title,
          // productHotCategory:0
        });
        console.log("财富增值", res.data);
        if (res.data.code == 200) {
          console.log(res);
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          sessionStorage.removeItem("index");
          // sessionStorage.removeItem("increaseWealthInfo");

          // this.plan.forEach((item) => {
          //   if (item.productHot === true) {
          //     this.data.push(item);
          //   }
          // });
          // console.log("data", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    //热销
    async request1 (num, title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productList({
          timeStamp,
          sign,
          pageSize: 6,
          // productCategory: this.zid,
          productHotCategory: this.zid,
          productHot: 1,
          pageNum: num,
          productTitle: title,
        });
        console.log("热销", res.data);
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    async requestHot (num, zid, title) {
      this.loading = true;
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productList({
          timeStamp,
          sign,
          pageSize: 6,
          // productCategory: this.zid,
          productHotCategory: this.hotZid,
          productHot: 1,
          pageNum: num,
          productTitle: title,
        });
        console.log("热销", res.data);
        if (res.data.code == 200) {
          this.total = res.data.total;
          this.plan = res.data.rows;
          this.length = this.plan.length;
          this.pages = res.data.pages;
          this.pageNum = res.data.pageNum;
          sessionStorage.removeItem("index");
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    //详情
    details (zid) {
      console.log("zid", zid);
      sessionStorage.setItem("DETAILS_ZID", zid); //存zid
      sessionStorage.setItem("DETAILS_CATEGORYTYPE", this.categoryType); //存
      sessionStorage.setItem("FALLBACK", this.list[this.id].categoryName); //存
      sessionStorage.setItem("FALLBACKID", this.id); //存
      sessionStorage.setItem("pageNum", this.pageNum);
      this.$router.push({
        // path: `/increaseWealth/increaseWealthDetails?categoryType=${this.categoryType}&index=${this.id}&zid=${zid}`,
        path: `/increaseWealth/increaseWealthDetails?categoryType=${this.categoryType}&index=${this.id}&zid=${zid}`,
        // query: {
        //   zid,
        // },
      });
    },
  },
  beforeDestroy () {
    sessionStorage.removeItem("increaseWealthInfo");
  },
  destroyed () {
    sessionStorage.removeItem("increaseWealthInfo");
  },
};
</script>
<style lang='scss' scoped>
/deep/.el-carousel__indicators{
  bottom: -15px;
}
main {
    width: 100%;
    height: auto;
    padding: 10rem 0;
    background-color: #b5dae6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > .main {
      width: 70vw;
      height: auto;
      // border: 1px solid #000;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-content: space-between;

      > li {
        background-color: #fff;
        //height: 27vw;
        height: 75rem;
        width: 31%;
        cursor: pointer;
        margin: 0 4.7rem 5rem 0;

        > img {
          width: 100%;
          //height: 55%;
          height: 30rem;
        }

        > div {
          padding: 1rem;
          box-sizing: border-box;
          //height: 45%;

          > p {
            font-size: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > .main_box {
            display: flex;
            justify-content: space-between;
            margin: 2rem 0;

            > .introduce {
              width: 100%;
              //overflow: hidden;
              //text-overflow: ellipsis;
              //display: -webkit-box;
              //-webkit-line-clamp: 5;
              //-webkit-box-orient: vertical;

              > li {
                font-size: 1.5rem;
                margin: 0.5rem 0;
                white-space: nowrap;
                p{
                  font-size: 1.5rem;
                }
                > p {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 1.5rem;
                }

                //p{
                //  //text-overflow: -o-ellipsis-lastline;
                //  overflow: hidden;
                //  text-overflow: ellipsis;
                //  display: -webkit-box;
                //  -webkit-line-clamp: 5;
                //  -webkit-box-orient: vertical;
                //}
              }
            }

            > .channel {
              > li {
                display: flex;
                align-items: center;
                margin: 0.5rem 0;
                white-space: nowrap;

                > p {
                  width: 0.3rem;
                  height: 1.5rem;
                  background: #003781;
                  margin-right: 1rem;
                }

                > div {
                  font-size: 1.5rem;
                }

                > img {
                  width: 13px;
                  height: 10px;
                  margin-right: 10px;
                }

                > .link {
                  color: #003781;
                }
              }
            }
          }
        }
      }

      > li:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
.longText {
  height: 30vh;
  justify-content: start;
}
.shortText {
  height: 17vh;
  justify-content: space-evenly;
}
.tabs {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  margin: 6rem 0;

  > .search {
    width: 90vw;
    justify-content: end;
    margin: auto;
    display: flex;
    align-items: center;

    /deep/ .el-input__inner {
      border: none !important;
      border-bottom: 1px solid !important;
      border-radius: 0 !important;
    }

    /deep/ .el-input {
      width: auto;
    }

    > img {
      width: 15rem;
      height: 15rem;
      margin-left: 1.5rem;
      cursor: pointer;
      margin-right: 10rem;
    }
  }
}
.banner_box {
  width: 90vw;
  margin: 2rem auto;

  .pic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .banner_title {
    margin: 3rem 0;
    font-size: 10rem;
  }

  div:nth-child(2) {
    font-size: 6.5rem;
    height: 10rem;
    line-height: 10rem;
  }

  div:nth-child(2):last-child {
    width: 70vw;
    text-align: center;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  /deep/ .el-carousel--horizontal {
    overflow-x: hidden;
    width: 100%;
  }

  /deep/ .el-carousel__container {
    position: relative;
    height: 50vw;
    width: 100%;
  }

  /deep/ .el-carousel__button {
    display: block;
    opacity: 0.48;
    width: 5px;
    height: 5px;
    background-color: #ccc;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
  }

  /deep/ .el-carousel__indicator.is-active button {
    // opacity: 1;
    background: #003781;
  }

  /deep/ .el-carousel__arrow {
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    // background-color: rgba(44, 56, 70, 0.11);
    background-color: #303133;
    opacity: 0.3;

    &:hover {
      opacity: 0.8;
    }
  }

  /deep/ .el-carousel__arrow i {
    font-size: 10rem;
    color: #000;
  }

  // .el-carousel__item:nth-child(2n) {
  //   background-color: #99a9bf;
  // }

  // .el-carousel__item:nth-child(2n + 1) {
  //   background-color: #d3dce6;
  // }
}
.mytable-scrollbar ::-webkit-scrollbar {
  width: 5px;
}
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920);
}
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}
.mobile-disclosure {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-bottom: px2rem(30, 375);

  header {
    width: 100%;
    height: px2rem(50, 375);
    display: flex;
    align-items: center;
    padding-left: px2rem(15, 375);
    img {
      width: px2rem(75, 375);
      height: px2rem(30, 375);
    }
  }
  .main-content {
    width: 100%;
    padding: px2rem(10, 375) px2rem(10, 375);
    padding-bottom: px2rem(120, 375);
    height: 100%;
    overflow-y: scroll;
    .price {
      width: 100%;

      > main {
        width: 100%;
        // height: 26vw;
        margin: auto;

        > p {
          text-align: center;
          font-size: px2rem(16, 375);
          margin: px2rem(10, 375) 0;
          color: #003781;
        }

        > .tab {
          //width: 60%;
          /* width: 106rem; */
          background: #edeff5;
          margin: auto;
          /* padding: 0 5rem 5rem; */

          .account {
            background: #edeff5;
            padding: px2rem(10, 375) px2rem(10, 375) px2rem(10, 375)
              px2rem(10, 375);
            font-size: px2rem(16, 375);
            /deep/ .van-field__label {
              width: unset;
            }
            /deep/ .van-field__control {
              font-size: calc(100vw / (375 / 10) * 12 / 10);
            }
            /deep/ .van-cell {
              padding: px2rem(6, 375) px2rem(12, 375);
              font-size: px2rem(12, 375);
            }
          }

          .pricingDay {
            padding: px2rem(10, 375) px2rem(10, 375) px2rem(10, 375)
              px2rem(10, 375);
            font-size: px2rem(16, 375);
          }
        }
      }
    }

    .view_btn {
      > .btn {
        font-size: px2rem(16, 375);
        display: flex;
        justify-content: center;
        color: #fff;
        width: 80%;
        margin: auto;
        justify-content: space-between;

        > a {
          color: #fff;

          > p {
            padding: px2rem(3, 375) px2rem(10, 375);
            background-color: #003781;
            margin: px2rem(10, 375) px2rem(1, 375);
            cursor: pointer;
          }
        }
      }
    }

    .report {
      width: 100%;

      .report_main {
        border-top: 1px solid;

        width: 95%;
        margin: auto;

        > p {
          font-size: px2rem(16, 375);
          margin: px2rem(10, 375);
          text-align: center;
        }

        > ul {
          margin-bottom: px2rem(10, 375);

          > li {
            display: flex;
            align-items: center;
            font-size: px2rem(16, 375);
            height: px2rem(20, 375);
            line-height: px2rem(20, 375);
            color: #004b98;
            cursor: pointer;

            > img {
              width: px2rem(15, 375);
              height: px2rem(10, 375);
              margin-right: px2rem(10, 375);
            }

            > a {
              text-decoration: none;
              color: #003781;
              font-size: px2rem(10, 375);
            }
          }
        }
      }
    }

    .login_box {
      width: 100%;

      .login {
        margin: px2rem(10, 375) auto px2rem(10, 375);
        background: url("~@/static/images/secondaryMenu/news/bg_kehu_login@2x.png");
        background-size: 100% 100%;
        color: #fff;
        padding: px2rem(10, 375) px2rem(10, 375);
        box-sizing: border-box;

        > div:first-child {
          font-size: px2rem(18, 375);
          display: flex;
          align-items: center;
          cursor: pointer;

          > a {
            color: #fff;
            display: flex;
            align-items: center;

            > img {
              width: px2rem(20, 375);
              height: px2rem(17, 375);
              margin-left: px2rem(5, 375);
            }
          }
        }

        > div:last-child {
          font-size: px2rem(12, 375);
          width: 75%;
          margin-top: px2rem(12, 375);
        }
      }
    }

    ::v-deep .paging {
      width: 100%;
      padding: 0;
      margin: 2rem 0;
      p{
        margin-left: 5rem;
        font-size: 6rem;
      }
      div{
        margin-right: 5rem;
        font-size: 6rem;
      }
    }
  }
  .bottom {
    width: 100%;
    background-color: #fff;
    padding: px2rem(10, 375) 0;
    position: absolute;
    bottom: 0;
    z-index: 9999;
    .qrcode {
      display: flex;
      justify-content: space-evenly;
      margin: px2rem(30, 375) 0;
      display: none;
      .item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
          width: px2rem(100, 375);
          height: px2rem(100, 375);
        }
        span {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .info {
      p {
        font-size: px2rem(13, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;

        &.beian {
          color: RGBA(38, 77, 139, 1);
        }
      }
    }
  }
}
</style>