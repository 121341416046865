<template>
  <MobileContainer v-if="isMobile && id == 'AZ022'" menuText="隐私政策" :date="time">
    <template #content>
      <div v-html="main" />
    </template>
  </MobileContainer>
  <!-- 关于我们详情 -->
  <div class="introduce" v-loading="loading" element-loading-text="拼命加载中" v-else>
    <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs_a">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="num" :to="{ path: year?year:id }">{{
          menu ? menu : "关于我们"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '' }">{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main" v-if="main">
      <div class="time">{{ flag ? "最新信息披露日期:" + time : "" }}</div>
      <!--      <main v-html="main"></main>-->
        <MobileMainContainer v-if="isMobile && isIos ">
          <template #content>
            <div v-html="main" />
          </template>
        </MobileMainContainer>
        <main v-else>
          <Editor :readonly="true" :content="main" :height="0"></Editor>
        </main>
      <!-- <main>
        <Editor :readonly="true" :content="main" :height="0"></Editor>
      </main> -->
    </div>
    <div class="empty" v-else>
      <!--      <div class="text">-->
      <!--        <p>暂无内容</p>-->
      <!--      </div>-->
      <empty></empty>
    </div>
  </div>
</template>

<script>
import { getContentInfo } from "@/api/disclosure";
import empty from "@/components/empty";
import Editor from "@/components/TinyMce";
import common from '@/utils/common'
import MobileContainer from '../../components/MobileContainer/index.vue'
import MobileMainContainer from '../../components/MobileMainContainer/VipTextIndex.vue'
import $ from "jquery";
export default {
  data() {
    return {
      main: null,
      title: null,
      time: "2022-09-20",
      loading: false,
      menu: null,
      flag: true,

      id:"",
      year:"",
      num:null,
      isMobile: false,
      isIos: false,
      isPad: false
    };
  },
  components: {
    empty,
    Editor,
    MobileContainer,
    MobileMainContainer
  },
  created() {
    this.isMobile = common.isMobile();
    this.isPad = window.navigator.userAgent.match(
        /(pad|pod|iPod|iPad)/i
    );
    this.isIos = window.navigator.userAgent.match(
        /(iPhone|iPod|ios|iPad)/i
    );
    if(this.isPad) {
      this.isMobile = false
    }
    console.log(this.$route.query);
    console.log("window.location.href", window.location.href);
    this.id = this.$route.params.id;
    this.year = this.$route.params.year;
    console.log("id11=========", this.$route.params.id);
    console.log("year======", this.year);
    // if (this.$route.params.id == 'AZ026'){
    //   this.menu = "产品服务"
    // }
    this.num = this.id == "AZ022" ? false : true;
    console.log("num", this.num);
    this.menu = sessionStorage.getItem("MENU");
    console.log("menu", sessionStorage.getItem("MENU"));

    if (sessionStorage.getItem("MENU") == "关于我们" ||sessionStorage.getItem("MENU") == "职业生涯" ||this.id=="AZ01506") {
      this.flag = false;
    }
    // http://localhost:9999/azContentPreview/about/AZ003
    // let link = window.location.href;
    // if (link.includes("azContentPreview/about")) {
    //   this.preview = true;
    //   console.log("预览");
    //   this.requestPreview();
    // } else {
    //   console.log("生产");
    //   this.request();
    // }
    this.request();
    this.isMobile = window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    var isPad = common.isPad();
      if(isPad){
				this.isMobile = false
			}
    console.log(this.isMobile);
    if (this.isMobile && this.id =="AZ022") {
      $("body").css("overflow", "hidden");
    }
  },
  mounted() {},
  methods: {
    str(str) {
      var reg = /^AZ025\/\/.*/g;
      let flag = reg.test(str);
      console.log(flag);
      return flag;
    },
    getStr(string, str) {
      console.log(string, str);
      let str_before = string.split(str)[0];
      return str_before;
    },
    async request() {
      this.loading = true;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getContentInfo({
          timeStamp,
          sign,
          contentType: this.$route.params.id,
          contentYearDate:this.year
        });
        console.log("111", res.data);
        if (res.data.code == 200) {
          console.log(res);
          // 累积生息利率及贷款利率公告信息分开展示介绍和详情
          if(this.id === 'AZ01506') {
            var contentContent = res.data.data.contentContent.split('<table')
            this.main = contentContent[0]
            localStorage.setItem("policyLoanRate", '<table'+contentContent[1])
          } else {
            this.main = res.data.data.contentContent;
          }
          this.title = res.data.data.contentTitle;
          if(this.year){
              this.title=this.title+"("+this.year+"年度)";
          }
          this.time = this.getStr(res.data.data.contentDate, "T");
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem("MENU");
  },
  destroyed() {
    sessionStorage.removeItem("MENU");
  },
  // watch: {
  //   $route() {
  //     // window.location.reload(); //监测到路由发生跳转时刷新一次页面
  //     sessionStorage.removeItem("MENU");
  //   },
  // },
};
</script>

<style scoped lang="less">
.introduce {
  .main {
    width: 100%;

    > .time {
      width: 1034px;
      text-align: right;
      margin: 5rem auto 2rem;
      font-size: 2.2rem;
    }

    > main {
      width: 1034px;
      // height: 700px;
      // border: 1px solid;
      margin: 0 auto 0rem;
    }

    //> .text {
    //  width: 80%;
    //  margin: auto;
    //  > p {
    //    font-size: 5rem;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    height: 25vw;
    //    color: #999;
    //  }
    //}
  }

  .empty {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

::v-deep.el-breadcrumb {
  padding: 10px 0;
  width: 80vw;
  margin: auto;
  font-size: 2rem;

  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}

::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}

::v-deep.el-loading-mask {
  z-index: 1;
}
</style>